<template>
    <div>
        <booking-quote-config></booking-quote-config>
        <div class="col-8 form-row ml-2 mb-3 form-material">
            <div class="form-check col-md-3 px-4 mt-3 checkbox-group pl-2">
                <input v-model="record.HideTotalPrice" class="form-check-input" type="checkbox"
                @change="change('HideTotalPrice')"
                 id="HideTotalPrice">
                <label for="HideTotalPrice" class="smaller form-check-label">{{tr('Hide Total Price')}}</label>
            </div>
            <div class="form-check col-md-3 px-4 mt-3 checkbox-group pl-2">
                <input v-model="record.DisableLinks" class="form-check-input" type="checkbox"
                @change="change('DisableLinks')"
                 id="DisableLinks">
                <label for="DisableLinks" class="smaller form-check-label">{{'Deshabilitar Itinerario'}}</label>
            </div>
            <div class="form-check col-md-3 px-4 mt-3 checkbox-group pl-2">
                <input v-model="record.DisableBreakdown" class="form-check-input" type="checkbox"
                @change="change('DisableBreakdown')"
                 id="DisableBreakdown">
                <label for="DisableBreakdown" class="smaller form-check-label">{{'Deshabilitar Breakdown'}}</label>
            </div>
            <div class="col-md-3">
                <number-input
                    :v.sync="record.HotelMarkup"
                    label="Hotel Mkp"
                    d="0"
                    @change="change('HotelMarkup')"
                ></number-input>
            </div>
        </div>
    </div>
</template>

<script>
const bookingQuoteConfig = importVueComp('components/booking', 'BookingQuoteConfig', 'custom');
export default {
    name: 'custom-booking-quote-config',
    mixins: [bookingQuoteConfig],
    components: {
        'booking-quote-config': bookingQuoteConfig,
    },
}
</script>

